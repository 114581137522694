.arco-menu-icon-suffix {
  display: none;
}

.arco-menu-vertical .arco-menu-inline-header {
  padding-right: 12px;
}

.arco-ellipsis-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
