.header {
  border-bottom: 1px solid var(--color-border-2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: calc(100% - 240px);
  right: 0;
  transition: width 0.1s linear;
  height: 48px;
  z-index: 999;
  background-color: var(--color-bg-1);
}
